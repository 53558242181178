<template>
  <BaseGenericTemplate>
    <div class="container">
      <h1 class="title is-size-3">Termos de uso</h1>
      <p class="article-subtitle">
        Última alteração: 23 de março de 2020, publicada em 23 de março de 2020
      </p>
      <div class="card-text">
        <article>
          <h2 class="title">Apresentação</h2>
          <p>
            O ESCALA10 PROVEDOR DE CONTEÚDO LTDA, inscrito no CNPJ sob o nº
            33.191.777/0001-30, com sede no Rio de Janeiro, único e exclusivo
            proprietário do domínio
            <a href="https://escala10.com.br">https://escala10.com.br</a>, que a
            partir de agora chamaremos apenas de ESCALA10, estabelece através
            deste TERMO DE USO as regras de utilização de cada USUÁRIO possuidor
            de CONTA em nosso site ou aplicativo.
          </p>
          <p>
            Por este TERMO DE USO, o USUÁRIO do ESCALA10 fica ciente e concorda
            que, ao utilizar os nossos serviços para estudo ou participação nas
            ligas disponíveis, automaticamente irá aderir e concordar em se
            submeter integralmente às condições deste TERMO DE USO.
          </p>
          <p>
            O ESCALA10 tem o direito de atualizar e modificar os TERMOS DE USO
            periodicamente, sem notificar antecipadamente a sua base de
            USUÁRIOS. O USUÁRIO tomará ciência desta atualização após a primeira
            tentativa de acesso ao ESCALA10, onde quaisquer novas
            funcionalidades e conteúdo estarão sujeitos a este TERMO DE USO. O
            USUÁRIO poderá acessar a versão mais recente dos TERMOS DE USO a
            qualquer momento através da seção MEUS DADOS no site ou na página do
            ESCALA10 na respectiva loja de aplicativos de onde foi feito o
            download.
          </p>
          <p>
            O USUÁRIO declara estar ciente de que qualquer violação das
            disposições apresentadas neste documento poderá resultar no
            cancelamento de sua CONTA sem qualquer notificação ou reembolso dos
            valores investidos.
          </p>
          <h2 class="title">Escopo dos serviços do Escala10</h2>
          <p>
            O ESCALA10 tem como objetivo oferecer aos adeptos dos FANTASY GAMES
            brasileiros, notícias, informações, indicadores e serviços de
            administração e gestão de LIGAS públicas e privadas para
            influenciadores digitais, baseadas no jogo virtual CARTOLA FC
            administrado pelo GLOBO COMUNICAÇÃO E PARTICIPAÇÕES S.A.
          </p>
          <p>
            Entende-se por FANTASY GAME todo tipo de jogo online onde os
            participantes escalam equipes imaginárias ou virtuais de jogadores
            reais de um esporte profissional, onde estas equipes competem entre
            si com base no desempenho estatístico dos jogadores escalados nas
            partidas reais. O CARTOLA FC é um FANTASY GAME sobre futebol no qual
            você pode montar seu time com jogadores reais da série A do
            campeonato brasileiro de futebol masculino.
          </p>
          <p>
            As notícias e informações são provenientes do compilado de postagens
            da conta do TWITTER dos SETORISTAS de cada clube participante da
            série A do campeonato brasileiro de futebol masculino. Entende-se
            por SETORISTA o jornalista que trabalha diariamente nos clubes e com
            fontes esportivas de determinado time de futebol brasileiro, sendo
            destes o devido crédito pelas notícias apresentadas no ESCALA10.
          </p>
          <p>
            Os indicadores são construídos a partir da combinação de bases de
            dados confiáveis do mercado esportivo, de acesso público e pagas,
            que permitem uma análise ampla e aprofundada dos principais
            parâmetros e fatores de decisão que o usuário do ESCALA10 poderá
            fazer uso no momento de escalar seu time a cada RODADA. Entende-se
            por RODADA no CARTOLA FC todas as partidas e clubes disponíveis para
            escalar jogadores que são realizadas dentro de um intervalo de tempo
            que pode variar de UM à QUATRO dias, que divide o campeonato
            brasileiro de futebol masculino em etapas ou RODADAS.
          </p>
          <p>
            O serviço de ADMINISTRAÇÃO DE LIGAS é destinado exclusivamente para
            INFLUENCIADORES DIGITAIS parceiros do ESCALA10. Entende-se por
            ADMINISTRAÇÃO DE LIGAS o desenvolvimento dos procedimentos para
            controle de inscrição, duração da liga, ranqueamento da premiação,
            apuração dos resultados e pagamento dos prêmios dos USUÁRIOS
            participantes conforme ranque previamente definido. Regras mais
            detalhadas são disponibilizadas no próprio site na seção LIGAS.
          </p>
          <p>
            Entende-se por LIGA qualquer competição organizada com base no jogo
            virtual CARTOLA FC onde os participantes competirão entre si regidos
            por REGRAS previamente esclarecidas e durante um número de rodadas
            previamente estabelecido.
          </p>
          <p>
            Cabe ao INFLUENCIADOR definir as REGRAS de ranqueamento e/ou
            premiação (esta última, se houver) e o acesso PÚBLICO ou PRIVADO
            para as suas ligas. As inscrições em ligas do tipo PRIVADA estão
            limitadas aos USUÁRIOS detentores de senha definida e comunicada
            pelo INFLUENCIADOR, enquanto as ligas do tipo PÚBLICAS estão abertas
            as inscrições de todo e qualquer USUÁRIO.
          </p>
          <h2 class="title">Nossas responsabilidades</h2>
          <p>
            As notícias e informações divulgadas pelos SETORISTAS de cada clube
            são de única e exclusiva responsabilidade destes, não recaindo sobre
            o ESCALA10 qualquer responsabilidade pela forma com que os USUÁRIOS
            farão uso delas.
          </p>
          <p>
            Os INDICADORES oferecidos pelo ESCALA10 representam cálculos
            matemáticos e estatísticos, dos mais simples aos mais complexos, que
            tem como base um conjunto de dados reais do histórico da temporada
            vigente dos clubes da série A do campeonato brasileiro de futebol
            masculino. Estes INDICADORES servem de apoio para análise e estudo
            dos USUÁRIOS para auxiliá-los no momento de escalar seus times no
            CARTOLA FC. Embora embasados em cálculos estatísticos, em se
            tratando de uma modalidade esportiva com inúmeras variáveis, o
            ESCALA10 não possui qualquer responsabilidade com os resultados da
            RODADA, tais como placares das partidas, escalação dos clubes,
            pontuação e scouts dos jogadores reais.
          </p>
          <p>
            As informações de pontuação e scouts dos atletas em tempo real
            disponibilizadas no decorrer de cada partida válida pelo CARTOLA FC
            são obtidas diretamente do provedor GLOBO COMUNICAÇÃO E
            PARTICIPAÇÕES S.A., ficando assim suscetível e limitada em casos de
            problema técnico manifestado pelo provedor, o que não incorre em
            responsabilidade para o ESCALA10.
          </p>
          <p>
            É responsabilidade do ESCALA10 disponibilizar o ranqueamento parcial
            das LIGAS em andamento durante a realização de cada rodada, ficando
            limitada aos problemas técnicos sofridos pelo provedor conforme
            descrito acima. Estende-se ainda a responsabilidade e transparência
            na apuração e divulgação do resultado de cada LIGA, que ocorrerá na
            próprio site ou aplicativo, onde todo e qualquer USUÁRIO terá acesso
            aos resultados. Os prêmios são creditados na conta dos usuários
            ranqueados e são resgatados mediante integradora de pagamento,
            responsável por este processo. A entrega de prêmios em produtos ou
            serviços ao USUÁRIO são de responsabilidade mútua deste e do
            ESCALA10, devendo ser alinhada à entrega em até QUINZE dias após
            apuração do resultado.
          </p>
          <p>
            O ESCALA10 disponibiliza ferramenta autônoma para recuperação e
            troca de senha, integradoras de pagamento para automatizar o
            processo de upgrade de ASSINATURA e recarga de crédito, além de
            serviço de atendimento ao consumidor para oferecer suporte as
            ocorrências em que o USUÁRIO não disponha de autonomia para
            resolver.
          </p>
          <p>
            O ESCALA10 se isenta por toda e qualquer responsabilidade prevista
            acima após consumada a data de expiração da ASSINATURA obtido pelo
            USUÁRIO. Os planos disponíveis estão divididos em: Semanal, Mensal,
            Turno, Temporada (completo) e Temporada (gratuito limitado)
          </p>
          <h2 class="title">Responsabilidades do usuário</h2>
          <p>
            O USUÁRIO deve manter seu cadastro sempre atualizado e com
            INFORMAÇÕES VERÍDICAS para facilitar o contato entre este e o
            ESCALA10.
          </p>
          <p>
            Entende-se por INFORMAÇÕES VERÍDICAS o nome verdadeiro do usuário e
            o devido CPF, telefone do usuário para fins de comunicação, e-mail
            válido para recuperação de senha, além de quaisquer outras
            informações necessárias para completar o processo de cadastro para
            criação da CONTA, sendo o USUÁRIO responsável pela veracidade das
            informações prestadas.
          </p>
          <p>
            O USUÁRIO é responsável por manter a segurança da sua CONTA e senha.
            O ESCALA10 não pode e não será responsável por qualquer perda ou
            dano de sua falha em cumprir esta obrigação de segurança.
          </p>
          <p>
            O USUÁRIO é responsável pelo uso das informações oferecidas pelo
            ESCALA10 bem como pela escalação dos seus times em qualquer FANTASY
            GAME.
          </p>
          <p>
            O USUÁRIO não pode utilizar o SERVIÇO do ESCALA10 para fins ilegais
            e não autorizados, bem como não deve, no uso do SERVIÇO, violar as
            leis em sua jurisdição e os termos estabelecidos neste documento
            (incluindo, mas não se limitando a leis de direitos autorais, de
            propriedade industrial e/ou intelectual).
          </p>
          <h2 class="title">Direitos autorais</h2>
          <p>
            Todos os direitos da marca ESCALA10 são de propriedade do ESCALA10
            PROVEDOR DE CONTEÚDO LTDA.
          </p>
          <p>
            O USUÁRIO não tem o direito de usar quaisquer marcas, logotipos,
            imagens do site ou aplicativo do ESCALA10, sendo que a infração
            estará sujeita a ações perante os órgãos responsáveis.
          </p>
          <h2 class="title">Processo de compra</h2>
          <p>
            Todo e qualquer USUÁRIO poderá obter acesso ao ESCALA10 através de
            uma CONTA gratuita, tendo neste caso, acesso LIMITADO as
            funcionalidades oferecidas no site e no aplicativo.
          </p>
          <p>
            Em qualquer momento, todo e qualquer USUÁRIO poderá migrar para uma
            CONTA PAGA, que poderá ser efetivada através dos meios de pagamento
            disponíveis no momento da compra, mediante o pagamento do valor
            aplicável, obtendo assim acesso COMPLETO as funcionalidades
            oferecidas pelo ESCALA10 durante a vigência do plano selecionado.
          </p>
          <p>
            Pela CONTA PAGA, o USUÁRIO pagará o valor estabelecido pelo canal de
            vendas no momento da compra. Em consonância com o direito de
            arrependimento do consumidor após a compra, previsto no artigo 49 do
            Código de Defesa do Consumidor, o consumidor pode desistir da
            aquisição da CONTA PAGA e receber seu dinheiro de volta no prazo de
            7 dias a contar da assinatura.
          </p>
          <p>
            Em exceção, os créditos gastos na inscrição de times em LIGAS não
            serão reembolsáveis.
          </p>
          <h2 class="title">Política de privacidade</h2>
          <p>
            Esta política de privacidade estabelece o uso da plataforma ESCALA10
            nas versões web e aplicativo criados por ESCALA10 PROVEDOR DE
            CONTEÚDO LTDA.
          </p>
          <h2 class="title">
            Quais informações a plataforma ESCALA10 obtém e como são utilizadas?
          </h2>
          <h2 class="title">Informações providas pelo USUÁRIO</h2>
          <p>
            O ESCALA10 obtém informações providas pelo USUÁRIO quando uma CONTA
            é criada através do site ou do aplicativo. Sem o cadastro em nossas
            plataformas através de uma CONTA não é possível acessar quaisquer
            funcionalidades oferecidas pelo ESCALA10.
          </p>
          <p>
            Ao criar uma CONTA, você concorda em fornecer (a) seu nome e
            sobrenome, número do cadastro de pessoa física (CPF), idade, (b)
            número de telefone com acesso à aplicação do WhatApp, endereço de
            residência, conta válida de e-mail e endereço completo, (c)
            informações providas quando você entra em contato com nosso suporte
            e (d) informações das compras e pagamentos realizados na plataforma.
          </p>
          <p>
            Todas as operações de compra e pagamento realizados dentro do site
            são intermediadas por agentes bancários, empresas de pagamento e
            empresas de comércio eletrônico, estando o USUÁRIO está sujeito as
            políticas de privacidade destas empresas. O ESCALA10 NÃO armazena
            quaisquer informações dos cartões de crédito utilizados nos
            processos de compra. No entanto, temos acesso a essas informações
            junto aos intermediadores quando na necessidade de resolver
            ocorrências referente a aprovação de pagamento solicitados pelos
            USUÁRIOS.
          </p>
          <p>
            O ESCALA10 também se reserva ao direito de usar as informações
            providas para fazer contato de tempos em tempos para prover ao
            USUÁRIO informações importantes, avisos obrigatórios e promoções.
          </p>
          <p>
            Todas as informações coletadas são armazenadas em banco de dados
            protegido e hospedados em servidores encriptados na nuvem.
          </p>
          <h2 class="title">Informações coletadas automaticamente</h2>
          <p>
            Além do mais, nossas plataformas podem coletar de forma automática
            informações, incluindo, mas não se limitando a, tipo de dispositivo
            usado no acesso, o ID único dos dispositivos móveis, endereço de IP
            do dispositivo, sistema operacional do dispositivo, tipo de
            navegador usado, e informações do modo com que a plataforma é
            utilizada.
          </p>
          <h2 class="title">
            A plataforma coleta informações precisas de localização em tempo
            real do dispositivo?
          </h2>
          <p>
            O ESCALA10 não coleta informações sobre a localização em tempo real
            do dispositivo.
          </p>
          <h2 class="title">
            Terceiros podem ver e/ou ter acesso às informações obtidas pela
            plataforma?
          </h2>
          <p>
            Apenas informações agregadas e dados anônimos podem ser transmitidos
            à terceiros no intuito de melhorar a plataforma e nossos serviços.
            Outro caso, o ESCALA10 PODE divulgar integralmente as informações
            providas pelo USUÁRIO e as informações coletadas de forma automática
            nos seguintes casos:
          </p>
          <ul>
            <li>
              Quando exigido por lei, como cumprir uma intimação ou processo
              legal semelhante;
            </li>
            <li>
              Quando o ESCALA10 acreditar de boa-fé que a divulgação seja
              necessária para proteger nossos direitos, proteger nossa segurança
              ou a segurança dos outros, investigar fraudes, ou responder alguma
              solicitação governamental;
            </li>
            <li>
              Quando prestadores de serviço confiáveis que trabalham com ou em
              nome do ESCALA10, não tem uso independente das informações que
              divulgamos a eles e concordamos em aderir às regras estabelecidas
              nesta declaração de privacidade;
            </li>
            <li>
              Se o ESCALA10 estiver envolvido em processo de fusão, aquisição ou
              venda de parte ou toda a empresa, o USUÁRIO será notificado por
              e-mail e/ou outros canais sobre mudanças na propriedade ou no uso
              dessas informações, bem como sobre quaisquer escolhas que você
              possa ter em relação a essas informações.
            </li>
          </ul>
          <h2 class="title">Quais são os direitos de exclusão do USUÁRIO?</h2>
          <p>
            O USUÁRIO poderá solicitar em qualquer momento a interrupção de toda
            coleta de informações da plataforma, seja pelo site ou pelo
            aplicativo, enviando um e-mail para o serviço de atendimento ao
            consumidor oferecido pelo Escala10 no endereço
            <span class="is-link">suporte10@escala10.com.br</span> solicitando o
            encerramento da CONTA.
          </p>
          <h2 class="title">
            Política de retenção de dados, gerenciando suas informações
          </h2>
          <p>
            O ESCALA10 irá reter as informações providas pelo USUÁRIO enquanto
            sua CONTA permanecer ativa e por um tempo razoável após sua
            solicitação de encerramento. As informações coletadas de forma
            automática serão mantidas por um período de até 24 meses e, depois
            disso, estas serão armazenadas em agregação para análises de mercado
            pelo ESCALA10. O USUÁRIO pode ainda solicitar que as informações
            providas sejam excluídas por completo de nossa base de dados
            enviando um e-mail para o endereço
            <span class="is-link">suporte10@escala10.com.br</span> onde a
            solicitação será respondida em tempo razoável. Ao aceitar este
            termo, o USUÁRIO concorda que algumas informações referentes a
            gestão de ligas não podem ser excluídas da nossa base de dados pois
            visam garantir a integridade e transparência na apuração de
            resultados, já que os demais USUÁRIOS poderão acessar essas
            informações durante a temporada vigente.
          </p>
          <h2 class="title">Segurança</h2>
          <p>
            O ESCALA10 tem preocupação em proteger a confidencialidade de suas
            informações. Nós dispomos de processos computacionais para proteger
            informações que serão processadas e mantidas. Nós limitamos acesso a
            estas informações apenas a colaboradores autorizados e prestadores
            de serviço que precisam saber destas informações a fim de operar,
            desenvolver ou melhorar nossa plataforma. Por favor, esteja ciente
            que, apesar dos nossos esforços para prover uma razoável segurança
            da informação que nós processamos e mantemos, nenhum sistema de
            segurança pode prevenir todas as brechas de segurança potenciais.
          </p>
          <h2 class="title">Mudanças</h2>
          <p>
            Este TERMO DE USO pode ser atualizado de tempos em tempos por
            qualquer razão. Nós iremos notificar os USUÁRIOS sobre quaisquer
            mudanças em nossa POLÍTICA DE PRIVACIDADE no momento de acessar sua
            CONTA, sendo solicitado que leia a nova política e aceite os novos
            termos.
          </p>
          <h2 class="title">Seu consentimento</h2>
          <p>
            Ao usar a plataforma ESCALA10 pelo site ou pelo aplicativo, você
            está consentindo com o processamento das informações conforme
            estabelecido nesta POLÍTICA DE PRIVACIDADE. Processar informações
            significa usar cookies no seu computador/celular ou manusear
            informação de outro modo, incluindo, mas não se limitando a,
            coletar, armazenar, deletar, usar, combinar e divulgar informações.
          </p>
        </article>
      </div>
    </div>
  </BaseGenericTemplate>
</template>

<script>
export default {
  name: "Policies",
};
</script>

<style lang="scss" scoped>
.article-subtitle {
  font-size: 0.8em;
  opacity: 0.5;
  margin-bottom: 0.5em;
}
.card-text {
  border-top: 3px solid #00f0f8;
  border-radius: 0 0 7px 7px;
  background-color: #292c3d;
  width: 100%;
  height: 100%;
  padding: 1em 5em;
  margin-bottom: 2em;

  @media (max-width: 658px) {
    & {
      padding: 1em 0.75em !important;
    }
  }

  article {
    .title,
    .is-link {
      color: #00f0f8 !important;
    }
    .is-link {
      font-weight: bold;
    }
    p {
      font-size: 0.9em;
      line-height: 1.6em;
      color: rgb(255, 255, 255);
      text-align: justify;
      margin-bottom: 1.5em;
    }

    ul {
      list-style: circle;
      li {
        margin-left: 2em;
        margin-bottom: 1em;
      }
    }
  }
}
</style>
